import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { Boton } from '../core/models/boton';
import { BotonesService } from '../core/services/botones.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { StorageService } from '../core/services/storage.service';
import { Configuracion } from '../core/models/configuracion';
import { Establecimiento } from '../core/models/establecimiento';
import { environment } from 'src/environments/environment';
import { DataCookie } from '../core/models/data-cookie';
import { CookieService } from 'ngx-cookie-service';
import { PoliticaCookiesComponent } from '../politica-cookies/politica-cookies.component';

@Component({
  selector: 'app-botones',
  templateUrl: './botones.component.html',
  styleUrls: ['./botones.component.scss']
})
export class BotonesComponent implements OnInit {

  listBotones:Boton[]=[];
  listConfiguracion:Configuracion[]=[];
  miEstablecimiento:Establecimiento= new Establecimiento();

  colorCuerpo:string;
  colorMenu:string;
  colorTextoCuerpo:string;
  colorTextoMenu:string;

  miCooKie:string=environment.strCookie;
  MesaId:string=environment.intIdMesa;

  strColorCuerpo:string=environment.strColorCuerpo;
  strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  muestraContenido=false;
  myLoader:boolean=false;
  strMesa:string;
  strZona:string;
  strNombreZona:string=environment.strNombreZona;
  strNombreMesa:string=environment.strNombreMesa;

  midataCookie:DataCookie=new DataCookie();
  cookieNombre = 'CookiePanelControlMesa';
  idEstablecimiento:string="";
  aceptCookies=false;
  cookieNombreNavegador="CookieNavegadorPanelControlMesa";
  
  constructor(private miservicio:BotonesService,private route: ActivatedRoute,private router: Router,public dialog: MatDialog,private cookieService: CookieService,
    private storageService: StorageService) {
      this.route.queryParams.subscribe(params => {
        this.idEstablecimiento=params["id"];
      });
    }

  ngOnInit() {  
    
    // this.listarDatos();
    // this.listarBotones();     
    this.myLoader=true;
    
    //console.log(this.midataCookie.coo);
    //console.log(sessionStorage.getItem(this.MesaId));

    // this.colorCuerpo=sessionStorage.getItem(this.strColorCuerpo);
    // this.colorTextoCuerpo=sessionStorage.getItem(this.strColorTextoCuerpo);

    // this.strMesa=sessionStorage.getItem(this.strNombreMesa);
    // this.strZona=sessionStorage.getItem(this.strNombreZona);

    // console.log(this.colorCuerpo)
    // console.log(this.colorTextoCuerpo)
    this.midataCookie=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
    this.ComprobarCookiesNavegador();
    setTimeout(()=>{
      this.colorCuerpo=this.midataCookie.strColorCuerpo;
      this.colorTextoCuerpo=this.midataCookie.strColorTextoCuerpo;
      this.strMesa=this.midataCookie.nombreMesa;
      this.strZona=this.midataCookie.nombreZona;
      this.listarBotones();
    }, 500)
  }

  // ngOnDestroy(){
  //   // alert('close')
  //   sessionStorage.clear();
  // }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event) {
    sessionStorage.clear();
  }

  listarDatos(){
    this.miservicio.getConfiguracion().subscribe(datos=>{
      // console.log(datos)
      this.listConfiguracion=datos;
      this.getDatosEst();
    })
  }

  getDatosEst(){
    this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      this.myLoader=false;
      this.muestraContenido=true;
      if(datos.miRespuesta.booOk){
        this.miEstablecimiento=datos.miListEstablecimiento[0];
        // console.log(this.miEstablecimiento)

        if(this.miEstablecimiento.strTelefono1!=null && this.miEstablecimiento.strTelefono1!=""){
          this.miEstablecimiento.strTelefono1=" - " + this.miEstablecimiento.strTelefono1;
        }
        if(this.miEstablecimiento.strTelefono2!=null && this.miEstablecimiento.strTelefono2!=""){
          this.miEstablecimiento.strTelefono2=" - " + this.miEstablecimiento.strTelefono2;
        }
        if(this.miEstablecimiento.strDireccion!=null && this.miEstablecimiento.strDireccion!=""){
          this.miEstablecimiento.strDireccion=" - " + this.miEstablecimiento.strDireccion;
        }
        if(this.miEstablecimiento.strPoblacion!=null && this.miEstablecimiento.strPoblacion!=""){
          this.miEstablecimiento.strPoblacion=" - " + this.miEstablecimiento.strPoblacion;
        }
        if(this.miEstablecimiento.strCp!=null && this.miEstablecimiento.strCp!=""){
          this.miEstablecimiento.strCp=" - " + this.miEstablecimiento.strCp;
        }
        if(this.miEstablecimiento.strProvincia!=null && this.miEstablecimiento.strProvincia!=""){
          this.miEstablecimiento.strProvincia=" - " + this.miEstablecimiento.strProvincia;
        }

        // setTimeout(() => {
        //   // this.router.navigate(['/ServciosMesa']);
        // }, 1000);  
        
      }
    })
  }

  listarBotones(){
    this.miservicio.getBotones(this.midataCookie.coo).subscribe(datos=>{
      // console.log(datos)
      this.listBotones=datos;
      //console.log(this.listBotones)
      this.listarDatos();
    })
  }

  // datosEstablecimiento(){
  //   this.miservicio.getEstablecimiento(this.midataCookie.coo).subscribe(datos=>{  
  //     console.log(datos)   
  //     this.listEstablecimiento=datos;

  //     // this.listarBotones();
  //   })
  // }

  pulsa(boton:Boton){
    // console.log(boton)
    window.open(boton.strDominio +"?coo="+this.midataCookie.coo+"&est="+ boton.intEstablecimientoId +"&pro="+ boton.intId +"&mes="+this.midataCookie.mes+" ", "_self");
    // window.open(this.listConfiguracion[0].strDominio +"/servicios/solicita?est="+ boton.intEstablecimientoId +"&pro="+ boton.intId +"&mes="+sessionStorage.getItem(this.MesaId)+" ", "_blank");
  }

  //PARA COOKIES DEL NAVEGADOR
  ComprobarCookiesNavegador(){
    let IsCookieExists:boolean=this.cookieService.check(this.cookieNombreNavegador+this.idEstablecimiento);
    if(IsCookieExists){
      this.aceptCookies=false;
    }else{
      this.aceptCookies=true;
    }
  }


  cancelar(){
    this.aceptCookies=false;
  }

  aceptaCookies(){
    this.cookieService.set(this.cookieNombreNavegador+this.idEstablecimiento,'PanelControlMesa',365,'',window.location.hostname );
    this.aceptCookies=false;
  }

  // removeCookies(){
  //   this.cookieService.delete(this.cookieNombreNavegador+this.idEstablecimiento,'',window.location.hostname);
  // }

  cookies(){
    let dialogRef = this.dialog.open(PoliticaCookiesComponent, {
      width:'600px',
      data:this.idEstablecimiento
    });
    dialogRef.afterClosed().subscribe(result =>{
      if(result){
        this.cookieService.set(this.cookieNombreNavegador+this.idEstablecimiento,'PanelControlMesa',365,'',window.location.hostname);
        this.aceptCookies=false;
      }
    })
  }

}
