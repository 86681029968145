import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Session } from '../core/models/session.model';
import { StorageService } from '../core/services/storage.service';
import { environment } from 'src/environments/environment';
import { BotonesService } from '../core/services/botones.service';
import { CookieService } from 'ngx-cookie-service';
import { DataCookie } from '../core/models/data-cookie';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  cookie:string="";
  mesa:string="";
  idEstablecimiento:string="";
  idProducto:string="";
  //data:Session[]=[];
  miCooKie:string=environment.strCookie;
  MesaId:string=environment.intIdMesa;

  strNombreZona:string=environment.strNombreZona;
  strNombreMesa:string=environment.strNombreMesa;
  strColorCuerpo:string=environment.strColorCuerpo;
  strColorTextoCuerpo:string=environment.strColorTextoCuerpo;

  showInfi=false;
  showError=false;
  strMensajeError:string="";
  booContinuar=false;
  booContinuar2=false;

  cookieNombre = 'CookiePanelControlMesa';
  midataCookie:DataCookie=new DataCookie();
  midataCookieAux:DataCookie=new DataCookie();
  

  // strImagen:string;

  constructor(private miservicio:BotonesService,private route: ActivatedRoute,private cookieService: CookieService, private router:Router) {
    this.route.queryParams.subscribe(params => {
      this.cookie=params["coo"];
      this.mesa=params["mes"];
      this.idEstablecimiento=params["est"];
      this.idProducto=params["pro"];
    });
   }

  ngOnInit() {
    if(this.cookie!=undefined && this.cookie!=""){
      this.booContinuar=true;
    }else{
      this.booContinuar=false;
      this.strMensajeError += "Parece que se ha producido un error, asegurese de que tiene la cookie.Si no es así, vuelva a escanear el codigo QR.";
    }

    if(this.mesa!=undefined && this.mesa!=""){
      this.booContinuar2=true;
    }else{
      this.booContinuar2=false;
      this.strMensajeError+=" No se ha encontrado la mesa";
    }

    setTimeout(() => {
      if(this.booContinuar && this.booContinuar2){
        this.showError=false;
        this.showInfi=true;
        // sessionStorage.setItem(this.miCooKie, this.cookie);
        // sessionStorage.setItem(this.MesaId, this.mesa);

        this.getDatos();
      }else{
        this.showError=true;
        this.showInfi=false;
      }
    }, 500);
  }

  aceptaCookies(){
    this.cookieService.set(this.cookieNombre,'PanelControlMesa',365,'',window.location.hostname);
  }

  removeCookies(){
    this.cookieService.delete(this.cookieNombre+this.idEstablecimiento,'',window.location.hostname);
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event) {
    sessionStorage.clear();
    // console.log("close")
  }

  Aceptar(){
    window.history.back();
  }

  getDatos(){
    this.miservicio.getMesas(this.cookie,this.mesa).subscribe(data=>{
      // console.log(data)
      if(data.miRespuesta.booOk){

        //Guardo el nombre de la zona
        // sessionStorage.setItem(this.strNombreZona, data.miListMesa[0].strZonaNombre);
        //Guardo el nombre de la mesa
        // sessionStorage.setItem(this.strNombreMesa, data.miListMesa[0].strNombre);

        this.miservicio.getEstablecimiento(this.cookie).subscribe(datos=>{
          // console.log(datos)
          if(datos.miRespuesta.booOk){
            //Guardo los colores del establecimiento
            // sessionStorage.setItem(this.strColorCuerpo, "#"+datos.miListEstablecimiento[0].strColorCuerpo);
            // sessionStorage.setItem(this.strColorTextoCuerpo, "#"+datos.miListEstablecimiento[0].strColorTextoCuerpo);
            // this.strImagen=datos.miListEstablecimiento[0].strLogo

            let IsCookieExists:boolean=this.cookieService.check(this.cookieNombre+this.idEstablecimiento);
            if(!IsCookieExists){
              //Vacio el objeto
              // for(var prop in this.midataCookie) {
              //   if(this.midataCookie.hasOwnProperty(prop)) {
              //     this.midataCookie[prop] = '';
              //   }
              // }
              
              this.midataCookie.coo=this.cookie;
              this.midataCookie.est=this.idEstablecimiento;
              this.midataCookie.mes=this.mesa;
              this.midataCookie.pro=this.idProducto;
              this.midataCookie.nombreMesa=data.miListMesa[0].strNombre;
              this.midataCookie.nombreZona=data.miListMesa[0].strZonaNombre;
              this.midataCookie.strColorCuerpo = "#"+datos.miListEstablecimiento[0].strColorCuerpo
              this.midataCookie.strColorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo

              // console.log(this.midataCookie)
              this.cookieService.set(this.cookieNombre+this.idEstablecimiento,JSON.stringify(this.midataCookie),4,'',window.location.hostname);
            }else{
              this.removeCookies();

              //Vuelvo a crear la cookie
              this.midataCookie.coo=this.cookie;
              this.midataCookie.est=this.idEstablecimiento;
              this.midataCookie.mes=this.mesa;
              this.midataCookie.pro=this.idProducto;
              this.midataCookie.nombreMesa=data.miListMesa[0].strNombre;
              this.midataCookie.nombreZona=data.miListMesa[0].strZonaNombre;
              this.midataCookie.strColorCuerpo = "#"+datos.miListEstablecimiento[0].strColorCuerpo
              this.midataCookie.strColorTextoCuerpo="#"+datos.miListEstablecimiento[0].strColorTextoCuerpo

              this.cookieService.set(this.cookieNombre+this.idEstablecimiento,JSON.stringify(this.midataCookie),4,'',window.location.hostname);


              // this.midataCookieAux=JSON.parse(this.cookieService.get(this.cookieNombre+this.idEstablecimiento));
              // console.log(this.midataCookieAux)
            }
            // this.cookieService.set(this.cookieNombre,'PanelControlMesa',365,'',window.location.hostname);

            setTimeout(() => {
              let extra: NavigationExtras = {
                queryParams: {
                  'id':this.idEstablecimiento
                }
              }
              this.router.navigate(['/ServciosMesa'], extra);
            }, 1000);  
            
          }else{
            this.showError=true;
            this.showInfi=false;
            this.strMensajeError=data.miRespuesta.strMensaje;
          }
        })
      
      }else{
        // console.log(77)
        this.showError=true;
        this.showInfi=false;
        this.strMensajeError=data.miRespuesta.strMensaje;
      }
    })
  }
}

// export interface dataCookie{
//   coo?:any;
//   est?:any;
//   pro?:any;
//   mes?:any;
//   nombreMesa?:any;
//   nombreZona?:any;
// }


